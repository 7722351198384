import api from "../configs/api";


export default class ClinicalService {

    async save(clinical) {
        return api.post("/clinical", clinical);
    }

}

import api from "../configs/api";


export default class UserService {

    async save(user) {
        return api.post("/signup", user);
    }

}

<template>
  <div class="card mt-3">
    <div class="surface-card p-4 mt-4 shadow-2 border-round">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12">
          <label for="clinica" :class="{'p-error': v$.user.name.$error}">Nome do Admin*</label>
          <div class="p-inputgroup">
            <InputText id="clinica" type="text"
                       required="true"
                       maxlength="50"
                       @blur="v$.user.name.$touch"
                       autofocus
                       v-model="user.name"
                       :class="{'p-invalid': v$.user.name.$error}"
            />
          </div>
          <small class="p-error" v-for="error of v$.user.name.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-12">
          <label for="cnpj" :class="{'p-error': v$.user.email.$error}">E-mail</label>
          <div class="p-inputgroup">
            <InputText id="cnpj"
                       @blur="v$.user.email.$touch"
                       autofocus
                       :class="{'p-invalid': v$.user.email.$error}"
                       v-model="user.email"/>
          </div>
          <small class="p-error" v-for="error of v$.user.email.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="celular" :class="{'p-error': v$.user.password.$error}">Senha*</label>
          <div class="p-inputgroup">
            <Password
                toggleMask
                id="celular"
                @blur="v$.user.password.$touch"
                autofocus
                :class="{'p-invalid': v$.user.password.$error}"
                v-model="user.password"/>
          </div>
          <small class="p-error" v-for="error of v$.user.password.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field mb-4 col-9 md:col-6">
          <label for="cep" :class="{'p-error': v$.user.confirmar.$error}">Confirmar Senha*</label>
          <div class="p-inputgroup">
            <Password id="cep"
                      toggleMask
                      @blur="v$.user.confirmar.$touch"
                      autofocus
                      :class="{'p-invalid': v$.user.confirmar.$error}"
                      v-model="user.confirmar"/>
          </div>
          <small class="p-error" v-for="error of v$.user.confirmar.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="col-12 text-right">
          <Button label="Anterior" icon="pi pi-angle-left" icon-pos="left" class="w-auto mt-3 text-right mr-2" @click.prevent="prevPage"></Button>
          <Button label="Próximo" icon="pi pi-angle-right" icon-pos="right" class="w-auto mt-3 text-right" @click.prevent="nextPage"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, required, sameAs} from "@vuelidate/validators";
import ClinicalService from "@/services/ClinicalService";
import UserService from "@/services/UserService";

export default {
  name: "Step2",
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.clinicalService = new ClinicalService();
    this.userService = new UserService();
  },
  mounted() {
  },
  props: {
    formData: Object
  },
  data() {
    return {
      user: {},
      clinical: this.formData.clinical,
      clinicalService: null,
      userService: null
    }
  },
  validations() {
    return {
      user: {
        name: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required)
        },
        email: {
          required: helpers.withMessage('Campo E-mail é obrigatório.', required)
        },
        password: {
          required: helpers.withMessage('Campo Senha é obrigatório.', required)
        },
        confirmar: {
          sameAs: helpers.withMessage('A senha informada não confere.', sameAs(this.user.password))
        },

      }
    }
  },
  methods: {

    async nextPage() {
      const isFormValid = await this.v$.$validate();
      if (isFormValid) {
        this.loader = this.$loading.show();

        await this.saveClinical();
        await this.saveUser()
        this.loader.hide();
      }
    },

    prevPage() {
      this.$emit('prev-page', {pageIndex: 1});
    },

    async saveClinical() {
      console.info(`JSON CLINICAL: ${JSON.stringify(this.clinical)}`);
      const response = await this.clinicalService.save(this.clinical);
      if(response.status === 200) {
        this.clinical = response.data;
      }
    },

    async saveUser() {
      this.user.clinicalId = this.clinical.id;

      console.info(`JSON CLINICAL: ${JSON.stringify(this.user)}`);
      const response = await this.userService.save(this.user);
      if(response.status === 200) {
        this.$emit('next-page', {
          formData: {
            user: this.user
          },
          pageIndex: 1
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
